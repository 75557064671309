import React from "react";
import Root from "../components/Root";
import SEO from "../components/seo";
import AboutPage from "../components/About/AboutPage";

const About = () => {
  return (
    <Root>
      <SEO title="About us" />
      <AboutPage />
    </Root>
  );
};

export default About;
