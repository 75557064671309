import React from "react";
import { navigate } from "gatsby";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import BlurrUpImage from "../constant/BlurUpImage";

import { trackCustomEvent } from "gatsby-plugin-google-analytics";

const useStyles = makeStyles(theme => ({
  root: {},
  section: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
  },
  whiteSection: {
    background: theme.palette.background.default,
    backgroundColor: theme.palette.background.default,
  },
  image: {
    maxWidth: 440,
    borderRadius: 220,
    overflow: "hidden",
    transition: "box-shadow 500ms",
    margin: "unset",
    "&:hover": {
      boxShadow:
        "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "80%",
      margin: "0 auto",
    },
  },
  mainTitle: {
    fontWeight: "700",
    [theme.breakpoints.down("sm")]: {
      fontSize: "3rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "5rem",
    },
  },
  startNowButton: {
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.25rem",
      fontWeight: "500",
    },
  },
  textGrid: {
    [theme.breakpoints.down("md")]: {
      marginTop: 64,
    },
  },
  // leftImageGrid: {
  //   [theme.breakpoints.up("lg")]: {
  //     flexDirection: "row",
  //   },
  //   [theme.breakpoints.down("md")]: {
  //     flexDirection: "column",
  //   },
  // },
  // rightImageGrid: {
  //   [theme.breakpoints.up("lg")]: {
  //     flexDirection: "row",
  //   },
  //   [theme.breakpoints.down("md")]: {
  //     flexDirection: "column",
  //   },
  // },
}));

const AboutPage = props => {
  const classes = useStyles(props);

  const handleStartNow = (e, label) => {
    trackCustomEvent({
      category: "Start Now",
      action: "Click",
      label: label,
      value: 0,
    });

    navigate("https://studio.app-mockup.com");
  };

  const imageOne = {
    alt: "Deaconu Dan Andrei",
    title: "Deaconu Dan Andrei",
    src: `https://media.app-mockup.com/assets/images/deaconu-dan-andrei.jpg`,
    blurSrc: `https://media.app-mockup.com/assets/images/deaconu-dan-andrei.jpg`,
    size: { width: 400, height: 400 },
  };

  const imageTwo = {
    alt: "Mihai Deaconu",
    title: "Mihai Deaconu",
    src: `https://media.app-mockup.com/assets/images/deaconu-mihai.jpg`,
    blurSrc: `https://media.app-mockup.com/assets/images/deaconu-mihai.jpg`,
    size: { width: 400, height: 400 },
  };

  return (
    <div className={classes.root}>
      <div className={clsx(classes.previewTab, classes.section)}>
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div style={{ textAlign: "center" }}>
                <Typography className={classes.mainTitle} variant="h2">
                  About Us
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div style={{ margin: "10px auto", maxWidth: 600, textAlign: "center" }}>
                <Typography variant="h6">
                  AppMockUp Studio is the an online design tool where app developers can create screenshots for the App Store & Play Store.
                  It is a free service and no account is required in order to use it.
                </Typography>
              </div>
            </Grid>

            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Button
                className={classes.startNowButton}
                size="large"
                color="primary"
                variant="contained"
                disableElevation
                onClick={e => handleStartNow(e, "Top Title")}
              >
                Start Now
              </Button>
            </Grid>
          </Grid>
        </Container>
      </div>

      <div className={clsx(classes.whiteSection, classes.section)}>
        <Container maxWidth="lg">
          <Grid className={classes.rightImageGrid} container spacing={2}>
            <Grid item xs={12} md={6}>
              <div className={classes.image}>
                <BlurrUpImage image={imageOne} />
              </div>
            </Grid>
            <Grid className={classes.textGrid} item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6">Developer, entreprenour, musician</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h2" style={{ maxWidth: 500 }}>
                    <strong>Deaconu Dan Andrei</strong>
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography style={{ margin: "10px 0px", maxWidth: 600, textAlign: "left" }} variant="h6">
                    I have been working as a full time iOS and Full Stack developer for the past 6 years, releasing apps and services that
                    are available on the App Store such as Blockwatch Mining Monitor, WhereToMine or O mie de semne. AppMockUp was built to
                    quell my frustration of having to spend countless hours updating screenshots for apps.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>

      <div className={clsx(classes.whiteSection, classes.section)}>
        <Container maxWidth="lg">
          <Grid className={classes.leftImageGrid} container spacing={2}>
            <Grid item xs={12} md={6}>
              <div className={classes.image}>
                <BlurrUpImage image={imageTwo} />
              </div>
            </Grid>
            <Grid className={classes.textGrid} item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6">Entreprenour, rebel, animal lover</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h2">
                    <strong>Mihai Deaconu</strong>
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography style={{ margin: "10px 0px", maxWidth: 600, textAlign: "left" }} variant="h6">
                    As Co-Founder of AppMockUp it is my responsability to ensure the company grows and reaches as many developers as
                    possible.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};

AboutPage.propTypes = {};

export default AboutPage;
